import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Code = makeShortcode("Code");
const Link = makeShortcode("Link");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`By default, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` issues short-lived certificates that expire after 24
hours. Short-lived certificates are excellent security hygiene because they
offer regular key rotation and `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/revocation"
      }}>{`passive revocation`}</a></p>
    <p>{`Short-lived certificates create a problem though: For any long-lived workloads,
you will need to renew your certificates each day before they expire. This
section will show three approaches to renew certs with `}<inlineCode parentName="p">{`step`}</inlineCode>{`.`}</p>
    <Alert severity="info" mdxType="Alert">
  <div>
    This section assumes you have initialized and started up a
    <Code mdxType="Code">step-ca</Code> instance using the steps in <Link href="/docs/step-ca/getting-started" mdxType="Link">Getting Started</Link>.  As an
    alternative, you can use our hosted CA, <Link href="/certificate-manager" mdxType="Link">Smallstep Certificate Manager</Link>.
  </div>
    </Alert>
    <h3>{`Creating short-lived certificates`}</h3>
    <p>{`First, let's initialize a new PKI and start `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`. We'll write a password
out to password.txt so we don't have to enter it repeatedly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ echo "p4ssword" > password.txt
$ step ca init --name "Speedy" --provisioner admin \\
               --dns localhost --address ":443" \\
               --password-file password.txt \\
               --provisioner-password-file password.txt
$ step-ca $(step path)/config/ca.json --password-file password.txt
`}</code></pre>
    <p>{`Now let's generate a `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/cli/ca/token/"
      }}>{`single-use bootstrap token`}</a>{` and use it to obtain a certificate:`}</p>
    <CodeBlock language="shell-session" copyText="TOKEN=$(step ca token --password-file password.txt foo.local)" mdxType="CodeBlock">
      {`$ TOKEN=$(step ca token --password-file password.txt foo.local)
✔ Key ID: w1OUFng_fCqWygHHpc9Ak8m_HGmE0TEasYIfahLoZUg (admin)`}
    </CodeBlock>
    <p>{`In a production environment, you might use something like Kubernetes or Chef to generate this token and give it to a host or client that needs a certificate.`}</p>
    <p>{`Now we can generate a keypair locally, and use our bootstrap token to obtain a certificate for `}<em parentName="p">{`foo.local`}</em>{` from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca certificate foo.local foo.crt foo.key --token $TOKEN
✔ CA: https://localhost
✔ Certificate: foo.crt
✔ Private Key: foo.key

$ step certificate inspect --short foo.crt
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 2599...1204]
  Subject:     foo.local
  Issuer:      Speedy Intermediate CA
  Provisioner: admin [ID: w1OU...oZUg]
  Valid from:  2019-05-01T21:06:25Z
          to:  2019-05-02T21:06:25Z
`}</code></pre>
    <p>{`By default, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` issues certificates valid for 24 hours. This is suitably
short for many scenarios. If it's not right for you, you can adjust the
`}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#claims"
      }}><inlineCode parentName="a">{`defaultTLSCertDuration`}</inlineCode></a>{` per provisioner
or pass the `}<inlineCode parentName="p">{`--not-after`}</inlineCode>{` flag to the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/certificate"
      }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{` to adjust the
lifetime of an individual certificate. Very short lifetimes (eg. five minutes)
are better from a security perspective, but this can be difficult in
practice.`}</p>
    <p>{`With short-lived certificates, your services and hosts will need to renew their certificates regularly, by extending their lifetimes `}<em parentName="p">{`before they expire`}</em>{`.`}</p>
    <p>{`You can do this manually with the following command:`}</p>
    <CodeBlock language="shell" copyText="step ca renew --force foo.crt foo.key" mdxType="CodeBlock">
      {`$ step ca renew --force foo.crt foo.key
Your certificate has been saved in foo.crt \n
$ step certificate inspect --short foo.crt
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 1664...3445]
  Subject:     foo.local
  Issuer:      Speedy Intermediate CA
  Provisioner: admin [ID: w1OU...oZUg]
  Valid from:  2019-05-01T21:15:16Z
          to:  2019-05-02T21:15:16Z
`}
    </CodeBlock>
    <p><em parentName="p">{`Note the change in the validity period relative to the original certificate above.`}</em></p>
    <h3>{`Automated renewal`}</h3>
    <p>{`What good are short-lived certificates if we can't renew them automatically?`}</p>
    <p>{`Here are three options for setting up automated renewal of certificates using `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#renewal-using-systemd-timers"
          }}>{`Renewal using `}<inlineCode parentName="a">{`systemd`}</inlineCode>{` timers`}</a>{`. This is the preferred approach.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#the-standalone-step-renewal-daemon"
          }}>{`The standalone `}<inlineCode parentName="a">{`step`}</inlineCode>{` renewal daemon`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#-cron-based-renewal"
          }}><inlineCode parentName="a">{`cron`}</inlineCode>{`-based renewal`}</a>{`.`}</p>
      </li>
    </ul>
    <h4>{`Renewal using `}<inlineCode parentName="h4">{`systemd`}</inlineCode>{` timers`}</h4>
    <p>{`This approach runs a periodic `}<a parentName="p" {...{
        "href": "https://wiki.archlinux.org/index.php/Systemd/Timers"
      }}>{`systemd timer`}</a>{` for each certificate you want to keep current.
The timer will run a one-shot systemd service every few minutes.
The one-shot service checks the certificate and renews it if more than `}{`⅔`}{` of its lifetime has elapsed.
Upon renewal, the service can try to reload or restart a service using the certificate files, if it exists.
Custom post-renewal commands can be configured as well.`}</p>
    <p>{`We will leverage systemd `}<a parentName="p" {...{
        "href": "https://www.freedesktop.org/software/systemd/man/systemd.service.html#Service%20Templates"
      }}>{`service templates`}</a>{` to simplify configuration of certificate renewal for many target services.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`/etc/systemd/system`}</inlineCode>{`, we'll start by creating template files `}<inlineCode parentName="p">{`cert-renewer@.service`}</inlineCode>{`, and `}<inlineCode parentName="p">{`cert-renewer@.timer`}</inlineCode>{`.`}</p>
    <p>{`Service templates accept a single argument after the `}<inlineCode parentName="p">{`@`}</inlineCode>{`, called the `}<em parentName="p">{`service unit argument`}</em>{`.
For example, for `}<inlineCode parentName="p">{`cert-renewer@postgresql.service`}</inlineCode>{`, the service unit argument is `}<inlineCode parentName="p">{`postgresql`}</inlineCode>{`.
In the template, `}<inlineCode parentName="p">{`%i`}</inlineCode>{` represents the service unit argument.`}</p>
    <p>{`Let's review the service template first, then we'll look at the timer template.`}</p>
    <p>{`Create a service unit template file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ sudo touch /etc/systemd/system/cert-renewer@.service
`}</code></pre>
    <p>{`Add the following configuration:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ini"
      }}>{`[Unit]
Description=Certificate renewer for %I
After=network-online.target
Documentation=https://smallstep.com/docs/step-ca/certificate-authority-server-production
StartLimitIntervalSec=0
; PartOf=cert-renewer.target

[Service]
Type=oneshot
User=root

Environment=STEPPATH=/etc/step-ca \\
            CERT_LOCATION=/etc/step/certs/%i.crt \\
            KEY_LOCATION=/etc/step/certs/%i.key

; ExecCondition checks if the certificate is ready for renewal,
; based on the exit status of the command.
; (In systemd <242, you can use ExecStartPre= here.)
ExecCondition=/usr/bin/step certificate needs-renewal \${CERT_LOCATION}

; ExecStart renews the certificate, if ExecStartPre was successful.
ExecStart=/usr/bin/step ca renew --force \${CERT_LOCATION} \${KEY_LOCATION}

; Try to reload or restart the systemd service that relies on this cert-renewer
; If the relying service doesn't exist, forge ahead.
; (In systemd <229, use \`reload-or-try-restart\` instead of \`try-reload-or-restart\`)
ExecStartPost=/usr/bin/env sh -c "! systemctl --quiet is-active %i.service || systemctl try-reload-or-restart %i"

[Install]
WantedBy=multi-user.target
`}</code></pre>
    <p>{`(This file is maintained `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli/blob/master/systemd/cert-renewer@.service"
      }}>{`on GitHub`}</a>{`)`}</p>
    <p>{`With this template file in place, we can now ask systemd to start any `}<inlineCode parentName="p">{`cert-renewer@*.service`}</inlineCode>{`.`}</p>
    <p>{`For example, if you have a systemd service called `}<inlineCode parentName="p">{`postgresql.service`}</inlineCode>{`,
with certificate and key files located in `}<inlineCode parentName="p">{`/etc/step/certs/postgresql.crt`}</inlineCode>{` and `}<inlineCode parentName="p">{`/etc/step/certs/postgresql.key`}</inlineCode>{`,
you can manually run `}<inlineCode parentName="p">{`systemctl start cert-renewer@postgresql.service`}</inlineCode>{`
and systemd will immediately check that certificate's readiness for renewal, and potentially renew it.
If the certificate is successfully renewed, the `}<inlineCode parentName="p">{`postgresql`}</inlineCode>{` service will be reloaded or restarted.`}</p>
    <h4>{`Customizing a service unit`}</h4>
    <p>{`You'll often need to customize your service unit for a given service.`}</p>
    <p>{`For example, you may need to:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Deploy the certificate and key to a service`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Reload or restart additional dependent services`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Combine the certificate and key files into a bundled `}<inlineCode parentName="p">{`.pem`}</inlineCode>{` or a PKCS#12 `}<inlineCode parentName="p">{`.p12`}</inlineCode>{` file, as needed by some services`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Ping a health check service or perform another action after a specific certificate is renewed`}</p>
      </li>
    </ul>
    <p>{`Instead of modifying the service template, we'll use `}<em parentName="p">{`service template overrides`}</em>{` here.
The overrides live in the drop-in configuration directory for the service being custommized.`}</p>
    <p>{`Here's an example override for a `}<a parentName="p" {...{
        "href": "https://www.lighttpd.net/"
      }}>{`Lighttpd`}</a>{` service that uses Docker Compose and is not managed by systemd:`}</p>
    <p><inlineCode parentName="p">{`/etc/systemd/system/cert-renewer@lighttpd-docker.service.d/override.conf`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ini"
      }}>{`[Service]
; \`Environment=\` overrides are applied per environment variable. This line does not
; affect any other variables set in the service template.
Environment=CERT_LOCATION=/etc/docker/compose/lighttpd/certs/example.com.crt \\
            KEY_LOCATION=/etc/docker/compose/lighttpd/certs/example.com.key
WorkingDirectory=/etc/docker/compose/lighttpd

; Restart lighttpd docker containers after the certificate is successfully renewed.
ExecStartPost=/usr/local/bin/docker-compose restart
`}</code></pre>
    <Alert severity="info" mdxType="Alert">
  <div>
    <strong>A subtlety of service template overrides:</strong> Any <code>ExecStartPost</code> lines in your <code>override.conf</code> will run <i>in addition to</i> those in the service template. Furthermore, adding an <i>empty</i> <code>ExecStartPost=</code> override will disable the template's <code>ExecStartPost</code> line(s).
  </div>
    </Alert>
    <p>{`Here's a more complex example that calls the `}<a parentName="p" {...{
        "href": "https://grafana.com/docs/grafana/latest/http_api/data_source/"
      }}>{`Grafana Data source HTTP API`}</a>{` to refresh a client certificate stored in Grafana's configuration database.`}</p>
    <p><inlineCode parentName="p">{`/etc/systemd/system/cert-renewer@grafana-loki-datasource.service.d/override.conf`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ini"
      }}>{`[Service]
ExecStartPost=/usr/bin/env bash -c 'jq -n \\
             --rawfile ca_cert $STEPPATH/certs/root_ca.crt \\
             --rawfile client_cert $CERT_LOCATION \\
             --rawfile client_key $KEY_LOCATION \\
             -f /etc/systemd/system/cert-renewer@grafana-loki-datasource.service.d/datasource.jq \\
      | curl -s -X PUT \\
      -H @/etc/systemd/system/cert-renewer@grafana-loki-datasource.service.d/api_headers \\
      -d @- \\
      --cacert $STEPPATH/certs/root_ca.crt  \\
      https://grafana:3000/api/datasources/1 > /dev/null'

ExecStartPost=curl -s -m 10 --retry 5 https://hc-ping.com/a66...fbba2
`}</code></pre>
    <p>{`When the certificate is successfully renewed:`}</p>
    <ol>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`ExecStartPost`}</inlineCode>{` in the service template will attempt to reload or restart `}<inlineCode parentName="li">{`grafana-loki-datasource.service`}</inlineCode>{`—which will do nothing, because no service with that name exists.`}</li>
      <li parentName="ol">{`The `}<inlineCode parentName="li">{`ExecStartPost`}</inlineCode>{` in the override configuration will construct JSON and pass it to curl, updating the certificate and key in Grafana.`}</li>
      <li parentName="ol">{`If all goes well, the final `}<inlineCode parentName="li">{`ExecStartPost`}</inlineCode>{` in the override configuration will ping a health check service at `}<a parentName="li" {...{
          "href": "https://healthchecks.io/"
        }}>{`Healthchecks`}</a>{` that expects to hear from this unit daily.`}</li>
    </ol>
    <h4>{`Enabling `}<inlineCode parentName="h4">{`systemd`}</inlineCode>{` renewal timers`}</h4>
    <p>{`The final piece of the puzzle is the renewal timer. Timers and services go hand in hand in systemd:
A `}<inlineCode parentName="p">{`cert-renewer@postgresql.timer`}</inlineCode>{` will always trigger a corresponding `}<inlineCode parentName="p">{`cert-renewer@postgresql.service`}</inlineCode>{`.`}</p>
    <p>{`Therefore, instead of enabling the `}<inlineCode parentName="p">{`cert-renewer@*.service`}</inlineCode>{` service units directly, we'll enable timer units that will periodically trigger each service unit.`}</p>
    <p>{`Create a timer unit template file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ sudo touch /etc/systemd/system/cert-renewer@.timer
`}</code></pre>
    <p>{`Add the following configuration to the file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ini"
      }}>{`[Unit]
Description=Timer for certificate renewal of %I
Documentation=https://smallstep.com/docs/step-ca/certificate-authority-server-production
; PartOf=cert-renewer.target

[Timer]
Persistent=true

; Run the timer unit every 15 minutes.
OnCalendar=*:1/15

; Always run the timer on time.
AccuracySec=1us

; Add jitter to prevent a "thundering hurd" of simultaneous certificate renewals.
RandomizedDelaySec=5m

[Install]
WantedBy=timers.target
`}</code></pre>
    <p>{`(This file is maintained `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/cli/blob/master/systemd/cert-renewer@.timer"
      }}>{`on GitHub`}</a>{`)`}</p>
    <p>{`Timers using this template will run every 5-10 minutes,
with a randomized delay on each timer.
The randomized delay helps with `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Thundering_herd_problem"
      }}>{`thundering herd problems`}</a>{`
that can occur when many virtual machines that were provisioned together try to renew their certificates at the same time.`}</p>
    <p>{`With this template in place,
let's start timers for our `}<inlineCode parentName="p">{`postgresql`}</inlineCode>{` and `}<inlineCode parentName="p">{`grafana-server`}</inlineCode>{` renewer services:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ systemctl enable --now cert-renewer@postgresql.timer
Created symlink /etc/systemd/system/multi-user.target.wants/cert-renewer@postgresql.service → /etc/systemd/system/cert-renewer@.service.
$ systemctl enable --now cert-renewer@grafana-server.timer
Created symlink /etc/systemd/system/multi-user.target.wants/cert-renewer@grafana-server.service → /etc/systemd/system/cert-renewer@.service.
$ systemctl list-timers
NEXT                        LEFT          LAST                        PASSED       UNIT                                    ACTIVATES
Wed 2020-12-16 17:06:23 PST 8min left     n/a                         n/a          cert-renewer@postgresql.timer           cert-renewer@postgresql.service
Wed 2020-12-16 17:04:12 PST 6min left     n/a                         n/a          cert-renewer@grafana-server.timer       cert-renewer@grafana-server.service
...
`}</code></pre>
    <p>{`Your periodic timers are now running and will run on system startup.
You can override your timer units too, but you probably won't need to.`}</p>
    <h4>{`The standalone `}<inlineCode parentName="h4">{`step`}</inlineCode>{` renewal daemon`}</h4>
    <p>{`Another way to automate renewal is with the `}<inlineCode parentName="p">{`step`}</inlineCode>{` renewal daemon.
With this method, `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` operates as a daemon that will keep your certificates up-to-date:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca renew --daemon foo.crt foo.key
INFO: 2019/06/20 12:36:54 first renewal in 14h46m57s
INFO: 2019/06/21 03:14:23 certificate renewed, next in 15h17m31s
INFO: 2019/06/21 18:31:00 certificate renewed, next in 14h33m17s
ERROR: 2019/06/22 11:04:39 error renewing certificate: client
POST https://localhost/renew failed: Post https://localhost/renew: dial tcp [::1]:443: connect: connection refused
INFO: 2019/06/22 11:05:00 certificate renewed, next in 14h33m17s
`}</code></pre>
    <p>{`When daemonized, `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` will attempt a renewal when the certificate's lifetime is approximately two-thirds elapsed.
So, for a certificate with a 24 hour lifetime, it will attempt a renewal after about 16 hours.`}</p>
    <p>{`There is some random `}<em parentName="p">{`jitter`}</em>{` built into the daemon's schedule to prevent a large number of renewals from being sent to the CA simultaneously
(eg. by a multitude of virtual machines that were provisioned at the same time and have identical certificate expiration dates).`}</p>
    <p>{`If the CA is unreachable, renewals are retried every minute.`}</p>
    <p>{`You can trigger renewal anytime by sending a `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` signal to the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` process ID.`}</p>
    <p>{`You can add `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew --daemon`}</inlineCode></a>{` as a `}<inlineCode parentName="p">{`systemd`}</inlineCode>{` service that runs on startup and restarts as needed.`}</p>
    <p>{`Here's an example of setting up everything via `}<inlineCode parentName="p">{`systemd`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cat <<EOF | sudo tee /etc/systemd/system/step.service > /dev/null
[Unit]
Description=Step TLS Renewer for Foo service
After=network.target
StartLimitIntervalSec=0

[Service]
Type=simple
Restart=always
RestartSec=1
User=step
ExecStart=/usr/bin/step ca renew --daemon /home/step/foo.crt /home/step/foo.key

[Install]
WantedBy=multi-user.target
EOF
$ systemctl daemon-reload
`}</code></pre>
    <p>{`Be sure the `}<inlineCode parentName="p">{`User`}</inlineCode>{` has write access to the certificate and key you're renewing.`}</p>
    <p>{`Rescan the `}<inlineCode parentName="p">{`systemd`}</inlineCode>{` unit files:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ sudo systemctl daemon-reload
`}</code></pre>
    <p>{`Enable and start the service:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`sudo systemctl enable --now step
`}</code></pre>
    <h4>{`Notifying Certificate-Dependent Services`}</h4>
    <p>{`Many services that depend on certificates will only read the certificate files on startup.
So when you renew a certificate, a server process that depends on it may not detect that it has changed.`}</p>
    <p>{`It's common for services (eg. `}<inlineCode parentName="p">{`nginx`}</inlineCode>{`) to respond to a `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` signal by reloading configuration files and certificates.
To address this, `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` can send a `}<inlineCode parentName="p">{`SIGHUP`}</inlineCode>{` to your service after each renewal.
Here's an example for `}<inlineCode parentName="p">{`nginx`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca renew --daemon --exec "kill -HUP $NGINX_PID" foo.crt foo.key
INFO: 2019/05/01 14:22:18 first renewal in 15h50m43s
`}</code></pre>
    <h4><inlineCode parentName="h4">{`cron`}</inlineCode>{`-based renewal`}</h4>
    <p>{`With `}<inlineCode parentName="p">{`cron`}</inlineCode>{`-based renewal, you can have `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` run at a regular cadence (eg. every five minutes) and renew a certificate only when it's approaching its expiration date.`}</p>
    <p>{`You can request that certificate renewal only occur if the certificate is approaching its expiry using the `}<inlineCode parentName="p">{`--expires-in <duration>`}</inlineCode>{` flag.
The `}<inlineCode parentName="p">{`<duration>`}</inlineCode>{` is a time interval like `}<inlineCode parentName="p">{`4h`}</inlineCode>{` or `}<inlineCode parentName="p">{`30m`}</inlineCode>{`.
Renewal will only occur if the expiry is within `}<inlineCode parentName="p">{`<duration>`}</inlineCode>{` of the current time.
For example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca renew --force --expires-in 4h foo.crt foo.key
certificate not renewed: expires in 23h58m44s
$ step ca renew --force --expires-in 24h foo.crt foo.key
Your certificate has been saved in foo.crt.
`}</code></pre>
    <p>{`With `}<inlineCode parentName="p">{`--expires-in`}</inlineCode>{`, we add a random `}<em parentName="p">{`jitter`}</em>{` to `}<inlineCode parentName="p">{`<duration>`}</inlineCode>{` (between 0 and `}<inlineCode parentName="p">{`<duration>/20`}</inlineCode>{`). This helps with `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Thundering_herd_problem"
      }}>{`thundering herd problems`}</a>{` if many virtual machines that were provisioned together try to renew their certificates at the same time.`}</p>
    <p>{`Here's an example of setting up renewal via `}<inlineCode parentName="p">{`cron`}</inlineCode>{` on a Debian-based system:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cat <<EOF | sudo tee /etc/cron.d/step-ca-renew
# Check for possible certificate renewal every five minutes
*/5 * * * *   step   step ca renew --force --expires-in 4h /home/step/foo.crt /home/step/foo.key
EOF
`}</code></pre>
    <p>{`Be sure the user (in this example, `}<inlineCode parentName="p">{`step`}</inlineCode>{`) has write access to the certificate and key you're renewing.`}</p>
    <h3>{`Caveats of Automated Renewal`}</h3>
    <h4>{`Revoking a Certificate`}</h4>
    <p><a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/renew"
      }}><inlineCode parentName="a">{`step ca renew`}</inlineCode></a>{` allows a certificate owner to extend the lifetime of a certificate before it expires.
Unfortunately, it also lets an attacker with the private key do the same thing.
To prevent this, you need to explicitly tell `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` to revoke a retired certificate.
See the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/revocation"
      }}>{`certificate revocation section`}</a>{` for details.`}</p>
    <h4>{`Renewal after expiry: For intermittently-connected devices`}</h4>
    <p>{`You can configure a provisioner to allow certificates to be renewed after they expire.
This is not the default behavior,
but it's useful if you have devices with intermittent network connectivity.
The device will be able to renew its certificate the next time it has network access,
provided the certificate has not been revoked.`}</p>
    <p>{`To configure renewal after expiry for a provisioner, pass the `}<inlineCode parentName="p">{`--allow-renewal-after-expiry`}</inlineCode>{` flag to the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/provisioner"
      }}><inlineCode parentName="a">{`step ca provisioner`}</inlineCode></a>{` management commands. See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners/#managing-provisioners"
      }}>{`managing provisioners`}</a>{`.`}</p>
    <Alert severity="warning" mdxType="Alert">
  <div>
	Enabling certificate renewal after expiry adds security risk.
	Consider alternatives, such as longer certificate lifetimes.
	If you enable this option, use the principle of least privilege and limit its availability as narrowly as possible.
  </div>
    </Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      